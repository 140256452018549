import React from "react"
import { graphql, PageProps } from "gatsby"
import { motion, useAnimation } from "framer-motion"

import Layout from "../components/layout"
import { Form, Description } from "../components/contact"
import SocialLinks from "../utils/sociallinks"
import { ContactQuery } from "./__generated__/ContactQuery"

export default ({ data, location }: PageProps<ContactQuery>) => {

    const Fade = ({ children }) => {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{
                    opacity: 1
                }}>
                {children}
            </motion.div>
        )
    }

    const api_url = data.site.siteMetadata.contact.api_url;
    const hasContactForm = (api_url);
    return (
        <Layout
            seo={{
                title: "Contact",
            }}
            location={location}
        >
            <Fade>
                <div className="container mx-auto py-12">
                    <div className="title text-center">
                        <h2 className="font-black text-5xl text-color-1">
                            Contact
                    </h2>
                    </div>

                    {/* PC */}
                    <div className="hidden lg:flex flex-wrap pb-40">
                        {hasContactForm &&
                            <div className="w-full lg:w-1/2 px-6">
                                <Form api={api_url} />
                            </div>
                        }
                        <div className={`w-full ${hasContactForm ? 'lg:w-1/2' : 'lg:w-2/3 mx-auto'} px-6 pt-8`}>
                            <div className="post-content" dangerouslySetInnerHTML={{ __html: data.microcmsGeneral.description }} />
                            <SocialLinks />
                        </div>
                    </div>

                    {/* スマホ */}
                    <div className="lg:hidden px-6 ">
                        <div className={`w-full`}>
                            <div className="post-content" dangerouslySetInnerHTML={{ __html: data.microcmsGeneral.description }} />
                            <SocialLinks />
                        </div>
                        {hasContactForm &&
                            <div className="w-full">
                                <Form api={api_url} />
                            </div>
                        }
                    </div>
                </div>
            </Fade>
        </Layout>
    )
}

export const query = graphql`
    query ContactQuery {
        microcmsGeneral(title: {eq: "contact"}) {
            description
        }
        site {
            siteMetadata {
                contact {
                    api_url
                }
            }
        }
    }
`
